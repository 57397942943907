import * as React from 'react'
import { css } from '@emotion/core'

import Attachment from '../components/Attachment'

const headerStyle = (theme) => css`
	${theme.typography.header};
`

const attachmentListContainer = css`
	display: flex;
	flex-wrap: wrap;
`

export const AttachmentList = ({ attachments, language }) => {
	return (
		<>
			<h3 css={headerStyle}>{language === 'en' ? 'Download': 'Pobierz'}</h3>
			<div css={attachmentListContainer}>
				{attachments.map((attachment, index) => (
					<Attachment key={index} color={attachment.color} title={attachment.title} url={attachment.url} />
				))}
			</div>
		</>
	)
}

export default AttachmentList
