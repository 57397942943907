import * as React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Content from '../components/Content'
import AttachmentList from '../components/AttachmentList'
import ContentGrid from '../components/ContentGrid'

const StaticPageComponent = ({ attachments, author, title, html, htmlPreview, menu, column, linkToDifferentLanguage, language, preview }) => {
	return (
		<Layout menu={menu} excludeHeader={preview} linkToDifferentLanguage={linkToDifferentLanguage} language={language}>
			<SEO title={title} />
			<Content title={title} author={author} html={html} htmlPreview={htmlPreview} column={column} language={language} />
			<ContentGrid>
				{attachments && attachments.length > 0 && <AttachmentList attachments={attachments} language={language} />}
			</ContentGrid>
		</Layout>
	)
}

export default StaticPageComponent
