import React from 'react'

import StaticPageComponent from '../components/StaticPageComponent'
import { graphql } from 'gatsby'
import { parseStaticPage } from '../parsers'


const StaticPage = ({ data, pageContext }) => {
	const lang = pageContext.language
	let { attachments, author, title, html, menu, column, en_body, en_title, en_column, slug, enSlug } = parseStaticPage({
		markdownRemark: data[lang],
	})
	const choosenTitle = lang === 'pl' ? title : en_title
	let linkToDifferentLanguage = enSlug
	if (lang === 'en') {
		title = en_title
		html = en_body
		column = en_column
		linkToDifferentLanguage = slug
	}

	return (
		<StaticPageComponent
			language={lang}
			attachments={attachments}
			author={author}
			title={choosenTitle}
			html={html}
			menu={menu}
			column={column}
			linkToDifferentLanguage={linkToDifferentLanguage}
		/>
	)
}

export default StaticPage

export const query = graphql`
	query($path: String!) {
		pl: markdownRemark(fields: { slug: { eq: $path } }) {
			...StaticPageData
		}
		en: markdownRemark(fields: { enSlug: { eq: $path } }) {
			...StaticPageData
		}
	}
`
