import * as React from 'react'
import { css } from '@emotion/core'

import ExternalLink from './ExternalLink'
import downloadIcon from '../images/download-icon.svg'

const colorMap = {
	czerwony: 'red',
	zielony: 'green',
	niebieski: 'blue',
	czarny: 'black',
}

const attachmentStyle = (color) => (theme) => {
	return css`
		${theme.typography.bigVariant};
		display: flex;
		flex-direction: column;
		width: 115px;
		height: 140px;
		color: #fff;
		background-color: ${theme.colors[colorMap[color]]};
		text-transform: uppercase;
		text-align: center;
		padding: 15px;
		margin-bottom: 5px;
		justify-content: space-between;
		margin-right: 50px;
	`
}

const linkStyle = css`
	display: block;
	text-decoration: none;
	margin-right: 10px;
	max-width: 150px;
	margin-bottom: 10px;
`

const iconStyle = css`
	height: 50px;
`

const titleStyle = (theme) => css`
	color: ${theme.colors.ecru};
`

const Attachment = ({ color, title, url }) => {
	if (!url) {
		return null
	}
	const { publicURL } = url
	const urlSplittedByDot = publicURL.split('.')
	const extenstion = urlSplittedByDot && urlSplittedByDot[urlSplittedByDot.length - 1]
	const parsedUrl = publicURL
	return (
		<ExternalLink link={parsedUrl} css={linkStyle}>
			<div css={attachmentStyle(color)}>
				{extenstion}
				<img css={iconStyle} src={downloadIcon} alt="download" />
			</div>
			<div css={titleStyle}>{title}</div>
		</ExternalLink>
	)
}

export default Attachment
